import { MDXProvider } from "@mdx-js/react"
import { graphql } from "gatsby"
import { MDXRenderer } from "gatsby-plugin-mdx"
import * as React from "react"
import AnchorTag from "../components/AnchorTag"
import Box from "../components/Box"
import CodeBlock from "../components/CodeBlock"
import { H1, H2, H3, H4 } from "../components/Heading"
import Link from "../components/Link"
import SEO from "../components/SEO"
import Text from "../components/Text"

const shortcodes = {
    a: AnchorTag,
    h1: H1,
    h2: H2,
    h3: H3,
    h4: H4,
    pre: CodeBlock,
    Link,
}

export interface PostTemplateProps {
    data: {
        mdx: {
            body: any
            excerpt: string
            frontmatter: {
                title: string
                author: string
                tags: string[]
                type: string
            }
        }
    }
}

export default function PageTemplate({
    data: {
        mdx: { body, excerpt, frontmatter },
    },
}: PostTemplateProps) {
    return (
        <Box>
            <SEO title={frontmatter.title} description={excerpt} />
            <Text size="heading-07" css={{ mb: "$4" }}>
                {frontmatter.title}
            </Text>
            <Text size="body-03">
                <MDXProvider components={shortcodes}>
                    {/* @ts-ignore */}
                    <MDXRenderer frontmatter={frontmatter}>{body}</MDXRenderer>
                </MDXProvider>
            </Text>
        </Box>
    )
}

export const pageQuery = graphql`
    query PageQuery($id: String) {
        mdx(id: { eq: $id }) {
            id
            body
            excerpt(truncate: true, pruneLength: 130)
            timeToRead
            frontmatter {
                title
                author
                tags
                type
            }
        }
    }
`
