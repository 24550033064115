import * as React from "react"

export interface AnchorTagProps {
    title: string
    href: string
    references: any[]
    withoutLink: boolean
    withoutPopup: boolean
    children: any
}

export default function AnchorTag({
    title,
    href,
    references = [],
    withoutLink,
    withoutPopup,
    children,
    ...restProps
}: AnchorTagProps) {
    return <a {...restProps}>{children}</a>
}
